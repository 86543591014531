import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import OverviewCategoriesPage from 'src/pages/dashboard/categories';
import OverviewModifiersPage from 'src/pages/dashboard/modifiers';
import OverviewItemsPage from 'src/pages/dashboard/Items';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// ORDER

// INVOICE

// USER

// BLOG

// FILE MANAGER

// APP

// TEST RENDER PAGE BY ROLE

// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'categories', element: <OverviewCategoriesPage /> },
      { path: 'items', element: <OverviewItemsPage /> },
      { path: 'modifiers', element: <OverviewModifiersPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
