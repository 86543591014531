/* eslint-disable import/no-extraneous-dependencies */
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store';
//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <Auth0Provider
          domain="dev-d3vq1u452nbu4ded.us.auth0.com"
          clientId="6ZM6HcYiMV72bN1HzzGAzacvtdHifhAp"
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </Auth0Provider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
