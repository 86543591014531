import { Box, Container, Drawer, Popover } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import { mutate } from 'swr';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import { endpoints } from 'src/utils/axios';
import UserTableToolbar from 'src/sections/user/user-table-toolbar';
import { useTable } from 'src/components/table';
import { removeAddGroup, removeSaveMyItem } from 'src/features/group/groupSlice';
import { useGetAllGroups } from 'src/api/apis';
import Iconify from '../iconify';
import CustomTable from '../custom-table/custom-table';
import AddGroup from '../add-group/add-group';

export default function GroupSection() {
  const [openItemDrawer, setopenItemDrawer] = useState(false);
  const [openGroupDrawer, setopenGroupDrawer] = useState(false);
  const defaultFilters = {
    search: '',
  };
  const [filters, setFilters] = useState(defaultFilters);
  const group = useSelector((state) => state.group.group);
  const dispatch = useDispatch();
  const table = useTable();

  const { groups } = useGetAllGroups(filters.search);

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(
    () => () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    },
    [debounceTimeout]
  );

  const handleFilters = useCallback(
    (name, value) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeoutId = setTimeout(() => {
        table.onResetPage();
        setFilters((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }, 1500);

      setDebounceTimeout(timeoutId);
    },
    [debounceTimeout, table]
  );

  const breadcrumblist = [
    { name: 'Home', href: './' },
    {
      name: 'Menu Builder',
      href: './',
    },
    {
      name: 'Create Item',
      href: './',
    },
  ];

  // Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseItemDrawer2 = (val) => {
    mutate(endpoints.menuGroup.menu);
    setopenItemDrawer(val);
    setopenGroupDrawer(val);
    dispatch(removeAddGroup());
    dispatch(removeSaveMyItem());
  };
  const handleCloseItemDrawer = () => {
    mutate(endpoints.menuGroup.menu);
    setopenItemDrawer(false);
    setopenGroupDrawer(false);
    dispatch(removeAddGroup());
    dispatch(removeSaveMyItem());
  };

  const handleOpenGroupDrawer = useCallback(() => {
    if (group !== null || group !== undefined) {
      setopenGroupDrawer(true);
      handleClose();
    }
  }, [handleClose, group]);

  useEffect(() => {
    if (openItemDrawer) {
      // You can perform additional actions if needed
    }
  }, [openItemDrawer]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          width: '100%',
          height: '100%',
          OverflowY: 'scroll',
        }}
      >
        <Stack>
          <Container
            sx={{
              mt: { xs: 2, sm: 0, md: 0, lg: 0 },
              width: '100',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              size="medium"
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" sx={{ xs: 10, sm: 20 }} />}
              target="_blank"
              rel="noopener"
              onClick={handleOpenGroupDrawer}
            >
              Create new Category
            </Button>
          </Container>
        </Stack>
        <>
          <Container>
            <UserTableToolbar
              filters={filters}
              onFilters={handleFilters}
              //
              // roleOptions={_roles}
              // roleOptions={[]}
            />
          </Container>
          {/* {canReset && (
              <UserTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                onResetFilters={handleResetFilters}
                results={dataFiltered?.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )} */}
          <CustomTable list={groups} myitems={group} groups />
        </>
      </Box>
      <Drawer
        open={openGroupDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddGroup
          groupItem={group}
          breadcrumb={breadcrumblist}
          closeFunc={handleCloseItemDrawer2}
        />
      </Drawer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button
          variant="subtitle2"
          nowrap
          onClick={() => handleOpenGroupDrawer()}
          sx={{ cursor: 'pointer' }}
        >
          Edit
        </Button>
      </Popover>
    </Container>
  );
}

GroupSection.propTypes = {};
