import { lazy } from 'react';
// import { Outlet } from 'react-router-dom';
// auth
// import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
// import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// AUTH0
const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'));
// const Auth0Callback = lazy(() => import('src/pages/auth/auth0/callback'));

// ----------------------------------------------------------------------

// const authAuth0 = {
//   path: '/',
//   element: (
//     <GuestGuard>
//       <Suspense fallback={<SplashScreen />}>
//         <Outlet />
//       </Suspense>
//     </GuestGuard>
//   ),
//   children: [
//     {
//       path: 'login',
//       element: (
//         <AuthClassicLayout>
//           <Auth0LoginPage />
//         </AuthClassicLayout>
//       ),
//     },
//     {
//       path: 'callback',
//       element: <Auth0Callback />,
//     },
//   ],
// };

export const authRoutes = [
  {
    path: 'auth',
    // children: [authAmplify, authJwt, authFirebase, authAuth0],
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <Auth0LoginPage />
          </AuthClassicLayout>
        ),
      },
    ],
  },
];
