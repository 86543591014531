// @mui
import { Box } from '@mui/material';
import ItemSection from 'src/components/Items-section/item-section';
// import Container from '@mui/material/Container';

// ----------------------------------------------------------------------

export default function ItemsView() {
  return (
    <Box>
      <ItemSection />
    </Box>
  );
}
