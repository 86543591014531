// cartSlice.js
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    group: {},
    savedgroup: [],
    saveditem: [],
    saveditem2: [],
    savedmodifiers: [],
  },
  reducers: {
    addGroup: (state, action) => {
      // state.group.push(action.payload);
      state.group = action.payload;
      // state.group = action.payload;
    },
    removeAddGroup: (state) => {
      state.group = {};
    },
    saveMyGroup: (state, action) => {
      state.savedgroup.push(action.payload);
    },
    removeSaveMyGroup: (state) => {
      state.savedgroup = [];
    },
    replaceMyGroup: (state, action) => {
      state.savedgroup = action.payload;
    },
    filterMyGroup: (state, action) => {
      state.savedgroup = state.savedgroup.filter((item) => item.menuGroupId !== action.payload);
    },
    saveMyItem: (state, action) => {
      state.saveditem.push(action.payload);
    },
    filterMyItem: (state, action) => {
      state.saveditem = state.saveditem.filter((item) => item.menuItemId !== action.payload);
    },
    // saveMyItem2: (state, action) => {
    //   state.saveditem2.push(action.payload);
    // },
    replaceMyItem: (state, action) => {
      state.saveditem = action.payload;
    },
    removeSaveMyItem: (state) => {
      state.saveditem = [];
    },
    saveModifier: (state, action) => {
      state.savedmodifiers.push(action.payload);
    },
    filterModifier: (state, action) => {
      // Map through the savedmodifiers and apply the modification conditionally
      state.savedmodifiers = state.savedmodifiers.map((item) => {
        if (item.modifierId === action.payload.modifierId) {
          // If the IDs match, replace the item with the new data
          return action.payload;
        }
        // Otherwise, return the item unchanged
        return item;
      });
    },
    replacAllModifiers: (state, action) => {
      state.savedmodifiers = action.payload;
    },
    replaceModifier: (state, action) => {
      const index = state.savedmodifiers.findIndex(
        (item) => item.modifierId === action.payload.modifierId
      );
      if (index !== -1) {
        state.savedmodifiers[index] = action.payload; // Replace the found item
      }
    },
    deleteAModifier: (state, action) => {
      state.savedmodifiers = state.savedmodifiers.filter(
        (item) => item.modifierId !== action.payload.modifierId
      );
    },
    removeModifier: (state) => {
      state.savedmodifiers = [];
    },
  },
});

export const {
  addGroup,
  removeAddGroup,
  replaceMyItem,
  saveMyGroup,
  saveMyItem,
  filterMyItem,
  filterMyGroup,
  removeSaveMyItem,
  replaceMyGroup,
  saveModifier,
  filterModifier,
  replaceModifier,
  replacAllModifiers,
  removeModifier,
  deleteAModifier,
  removeSaveMyGroup,
  // saveMyItem2,
  refreshMenu,
} = groupSlice.actions;

export default groupSlice.reducer;
