export const AVAILABILITY = [
  {
    value: true,
    label: 'Available',
  },
  {
    value: false,
    label: 'Unavailable',
  },
];

export const OPTIONTYPE = [
  {
    value: 0,
    label: 'Check boxes',
  },
  {
    value: 1,
    label: 'Radio Buttons',
  },
];

export const REQUIRED = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];
