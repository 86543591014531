import { Helmet } from 'react-helmet-async';
import { ModifiersView } from 'src/sections/Modifiers/view';

// ----------------------------------------------------------------------

export default function OverviewModifiersPage() {
  return (
    <>
      <Helmet>
        <title> Modifiers</title>
      </Helmet>

      <ModifiersView />
    </>
  );
}
