// cartSlice.js
// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';

const savedItemSlice = createSlice({
  name: 'saveditem',
  initialState: {
    group: {},
    item: {},
  },
  reducers: {
    saveGroup: (state, action) => {
      state.group = action.payload;
    },
    saveItem: (state, action) => {
      state.item = action.payload;
    },
  },
});

export const { saveGroup, saveItem } = savedItemSlice.actions;

export default savedItemSlice.reducer;
