// @mui
import Container from '@mui/material/Container';
import GroupSection from 'src/components/group-section/group-section';

// ----------------------------------------------------------------------

export default function CategoryView() {
  return (
    <Container>
      <GroupSection />{' '}
    </Container>
  );
}
