// @mui
import Container from '@mui/material/Container';
// React
import { useCallback, useEffect, useState } from 'react';

// hooks
import { Box, Drawer } from '@mui/material';
import { mutate } from 'swr';
import { endpoints } from 'src/utils/axios';

// components
import { useSettingsContext } from 'src/components/settings';
import CreateMenu from 'src/components/create-menu/create-menu';
import MenuSection from 'src/components/menu-section/menu-section';

// ----------------------------------------------------------------------

export default function OverviewAppView() {
  const settings = useSettingsContext();

  // drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (openDrawer) {
      handleCloseDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDrawer = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
    mutate(endpoints.menu.menu);
  }, []);
  const handleCloseGroupDrawer2 = (val) => {
    setOpenDrawer(val);
    mutate(endpoints.menu.menu);
  };

  const breadcrumblist = [
    { name: 'Home', href: './' },
    {
      name: 'Menu Builder',
      href: './',
    },
    {
      name: 'Main Menu',
      href: './',
    },
  ];

  return (
    <Box>
      <Container
        maxWidth={settings.themeStretch ? false : 'xl'}
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',

          alignItems: 'start',
        }}
      >
        {/* <Box
          sx={{
            width: '40%',
            height: '100%',
            maxWidth: '250px',
            minWidth: '150px',
          }}
        >
          <Posmenubuilder OpenMenu={handleOpenDrawer} />
        </Box> */}
        <MenuSection OpenMenu={handleOpenDrawer} />
        {/* <Container
          sx={{
            width: '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'start',
          }}
        >
          <SubSection OpenMenu={handleOpenDrawer} />
        </Container> */}
      </Container>

      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={handleCloseDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            height: '100%',
            overflowY: 'hidden',
          },
        }}
      >
        <CreateMenu breadcrumb={breadcrumblist} closeMenu={handleCloseGroupDrawer2} flow />
      </Drawer>
    </Box>
  );
}
