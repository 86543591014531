/* eslint-disable dot-notation */
import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { useAuth0 } from '@auth0/auth0-react';
import axios, { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// export function useGetMenus() {
//   const URL = endpoints.menu.menu;
//   const { getAccessTokenSilently } = useAuth0();

//   if (axios.defaults.headers.common['Authorization'] === undefined) {
//     getAccessTokenSilently().then((token) => {
//       axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
//     });
//   }

//   const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

//   const memoizedValue = useMemo(
//     () => ({
//       menu: data || [],
//       menuLoading: isLoading,
//       menuError: error,
//       menuValidating: isValidating,
//       menuEmpty: !isLoading && !data?.length,
//     }),
//     [data, error, isLoading, isValidating]
//   );

//   return memoizedValue;
// }
export function useGetMenus(search) {
  // const URL = endpoints.menu.menu;
  const URL = search ? `${endpoints.menu.menu}?search=${search}` : endpoints.menu.menu;

  const { getAccessTokenSilently } = useAuth0();

  // Set up an Axios interceptor to attach the token to every request
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const fetchers = (url) => axios.get(url).then((res) => res.data);

  // useSWR hook to fetch the data
  const { data, isLoading, error, isValidating } = useSWR(URL, fetchers, options);

  // Memoize the value to only recompute the returned object when one of the dependencies has changed
  const memoizedValue = useMemo(
    () => ({
      menu: data || [],
      menuLoading: isLoading,
      menuError: error,
      menuValidating: isValidating,
      menuEmpty: !isLoading && !(data && data.length),
    }),
    [data, isLoading, error, isValidating]
  );

  return memoizedValue;
}

export function useGetAllGroups(search) {
  const URL = search ? `${endpoints.menuGroup.menu}?search=${search}` : endpoints.menuGroup.menu;

  const { getAccessTokenSilently } = useAuth0();

  // Set up an Axios interceptor to attach the token to every request
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const fetchers = (url) => axios.get(url).then((res) => res.data);

  // useSWR hook to fetch the data
  const { data, isLoading, error, isValidating } = useSWR(URL, fetchers, options);

  // Memoize the value to only recompute the returned object when one of the dependencies has changed
  const memoizedValue = useMemo(
    () => ({
      groups: data || [],
      groupsLoading: isLoading,
      groupsError: error,
      groupsValidating: isValidating,
      groupsEmpty: !isLoading && !(data && data.length),
    }),
    [data, isLoading, error, isValidating]
  );

  return memoizedValue;
}

// export function useGetAllGroups() {
//   const URL = endpoints.menuGroup.menu;
//   const { getAccessTokenSilently } = useAuth0();

//   // Set up an Axios interceptor to attach the token to every request
//   axios.interceptors.request.use(
//     async (config) => {
//       if (!config.headers.Authorization) {
//         const token = await getAccessTokenSilently();
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//       return config;
//     },
//     (error) => Promise.reject(error)
//   );

//   const fetchers = (url) => axios.get(url).then((res) => res.data);

//   // useSWR hook to fetch the data
//   const { data, isLoading, error, isValidating } = useSWR(URL, fetchers);

//   // Memoize the value to only recompute the returned object when one of the dependencies has changed
//   const memoizedValue = useMemo(
//     () => ({
//       groups: data || [],
//       groupsLoading: isLoading,
//       groupsError: error,
//       groupsValidating: isValidating,
//       groupsEmpty: !isLoading && !(data && data.length),
//     }),
//     [data, isLoading, error, isValidating]
//   );

//   return memoizedValue;
// }

export function useGetAllItems(search) {
  const URL = search ? `${endpoints.menuItem.menu}?search=${search}` : endpoints.menuItem.menu;

  const { getAccessTokenSilently } = useAuth0();

  // Set up an Axios interceptor to attach the token to every request
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const fetchers = (url) => axios.get(url).then((res) => res.data);

  // useSWR hook to fetch the data
  const { data, isLoading, error, isValidating } = useSWR(URL, fetchers, options);

  // Memoize the value to only recompute the returned object when one of the dependencies has changed
  const memoizedValue = useMemo(
    () => ({
      optionItems: data || [],
      optionItemsLoading: isLoading,
      optionItemsError: error,
      optionItemsValidating: isValidating,
      optionItemsEmpty: !isLoading && !(data && data.length),
    }),
    [data, isLoading, error, isValidating]
  );

  return memoizedValue;
}
// export function useGetAllItems() {
//   const URL = endpoints.menuItem.menu;
//   const { getAccessTokenSilently } = useAuth0();
//   if (axios.defaults.headers.common.Authorization === undefined) {
//     getAccessTokenSilently().then((token) => {
//       axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//     });
//   }
//   const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

//   const memoizedValue = useMemo(
//     () => ({
//       optionItems: data || [],
//       itemsLoading: isLoading,
//       itemsError: error,
//       itemsValidating: isValidating,
//       itemsEmpty: !isLoading && !data?.length,
//     }),
//     [data, error, isLoading, isValidating]
//   );

//   return memoizedValue;
// }

export function useGetAnItem(itemid) {
  const myid = String(itemid);
  console.log('itemid', myid);
  const URL = endpoints.menuItem.menu;
  const { getAccessTokenSilently } = useAuth0();
  if (axios.defaults.headers.common.Authorization === undefined) {
    getAccessTokenSilently().then((token) => {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    });
  }
  const { data, isLoading, error, isValidating } = useSWR(`${URL}/${myid}`, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      item: data || [],
      itemLoading: isLoading,
      itemError: error,
      itemValidating: isValidating,
      itemEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// export function useGetModifiers() {
//   const URL = endpoints.modifier.modifiers;
//   const { getAccessTokenSilently } = useAuth0();
//   if (axios.defaults.headers.common.Authorization === undefined) {
//     getAccessTokenSilently().then((token) => {
//       axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//     });
//   }
//   const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

//   const memoizedValue = useMemo(
//     () => ({
//       modifiersList: data || [],
//       modifiersLoading: isLoading,
//       modifiersError: error,
//       modifiersValidating: isValidating,
//       modifiersEmpty: !isLoading && !data?.length,
//     }),
//     [data, error, isLoading, isValidating]
//   );

//   return memoizedValue;
// }

export function useGetModifiers(search) {
  // const URL = endpoints.modifier.modifiers;
  const URL = search
    ? `${endpoints.modifier.modifiers}?search=${search}`
    : endpoints.modifier.modifiers;

  const { getAccessTokenSilently } = useAuth0();

  // Set up an Axios interceptor to attach the token to every request
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers.Authorization) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const fetchers = (url) => axios.get(url).then((res) => res.data);

  // useSWR hook to fetch the data
  const { data, isLoading, error, isValidating } = useSWR(URL, fetchers, options);

  // Memoize the value to only recompute the returned object when one of the dependencies has changed
  const memoizedValue = useMemo(
    () => ({
      modifiersList: data || [],
      modifiersListLoading: isLoading,
      modifiersListError: error,
      modifiersListValidating: isValidating,
      modifiersListEmpty: !isLoading && !(data && data.length),
    }),
    [data, isLoading, error, isValidating]
  );

  return memoizedValue;
}

export function useGetAllPrepStations() {
  const URL = endpoints.prepStations.prep;
  const { getAccessTokenSilently } = useAuth0();
  if (axios.defaults.headers.common.Authorization === undefined) {
    getAccessTokenSilently().then((token) => {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    });
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      prep: data || [],
      prepLoading: isLoading,
      prepError: error,
      prepValidating: isValidating,
      prepEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetTaxRates() {
  const URL = endpoints.taxRates.taxrates;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);

  const memoizedValue = useMemo(
    () => ({
      taxrates: data || [],
      taxratesLoading: isLoading,
      taxratesError: error,
      taxratesValidating: isValidating,
      taxratesEmpty: !isLoading && !data?.taxrates?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function useSearchPosts(query) {
  const URL = query ? [endpoints.post.search, { params: { query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results?.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function createMenu(payload) {
  const URL = endpoints.menu.menu;
  const res = await axios.post(URL, payload, {});
  return res;
}
export async function editMenu(id, payload) {
  const URL = endpoints.menu.menu;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function EditMenuItem(id, payload) {
  const URL = endpoints.menuItem.menu;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function createMenuItem(payload) {
  const URL = endpoints.menuItem.menu;
  const res = await axios.post(URL, payload, {});
  return res;
}

export async function createMenuGroup(payload) {
  const URL = endpoints.menuGroup.menu;
  const res = await axios.post(URL, payload, {});
  return res;
}
export async function editGroup(id, payload) {
  const URL = endpoints.menuGroup.menu;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function createImage(payload, filename) {
  const URL = endpoints.image.image;
  const res = await axios.post(`${URL}/${filename}`, payload, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
  return res;
}

export async function createModifier(payload) {
  const URL = endpoints.modifier.modifiers;
  const res = await axios.post(URL, payload, {});
  return res;
}

export async function editModifier(id, payload) {
  const URL = endpoints.modifier.modifiers;
  const res = await axios.put(`${URL}/${id}`, payload, {});
  return res;
}

export async function deleteModifier(id) {
  const URL = endpoints.modifier.modifiers;
  const res = await axios.delete(`${URL}/${id}`, {});
  return res;
}

export async function deleteMenu(id) {
  const URL = endpoints.menu.menu;
  const res = await axios.delete(`${URL}/${id}`, {});
  return res;
}

export async function DeleteMenuItem(id) {
  const URL = endpoints.menuItem.menu;
  const res = await axios.delete(`${URL}/${id}`);
  return res;
}

export async function deleteGroup(id) {
  const URL = endpoints.menuGroup.menu;
  const res = await axios.delete(`${URL}/${id}`);
  return res;
}
