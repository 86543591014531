import PropTypes from 'prop-types';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
// config
import { AUTH0_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

function AuthProviderWrapper({ children }) {
  const {
    isAuthenticated,
    user,
    isLoading,
    loginWithPopup,
    logout,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const [popupClick, setPopupClick] = useState(true);

  const checkTokenExpiration = async () => {
    if (isAuthenticated) {
      const idTokenClaims = await getIdTokenClaims();
      const currentTime = Math.floor(Date.now() / 1000);

      if (idTokenClaims.exp < currentTime) {
        try {
          // Attempt to refresh the access token using the refresh token
          await getAccessTokenSilently({
            audience: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}`,
          });
        } catch (error) {
          // If refresh fails or user is not logged in, perform logout
          handleLogout();
        }
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
    // Check token expiration every minute
    const intervalId = setInterval(checkTokenExpiration, 60000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently]);

  // LOGIN
  const handleLoginWithPopup = useCallback(
    async (options) => {
      loginWithPopup?.(options);
      setPopupClick(false);
    },
    [loginWithPopup]
  );

  // LOGOUT
  const handleLogout = useCallback(
    async (options) => {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    [logout]
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = popupClick && isLoading ? 'loading' : checkAuthenticated;

  // ...

  const memoizedValue = useMemo(
    () => ({
      user: {
        ...user,
        displayName: user?.name,
        photoURL: user?.picture,
        role: 'admin',
      },
      method: 'auth0',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      // loginWithRedirect,
      loginWithPopup: handleLoginWithPopup,

      // Pass the expires_in value to tokenExpired
      logout: handleLogout, // Remove the parentheses here
    }),
    [handleLoginWithPopup, handleLogout, status, user]
  );

  // ...

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProviderWrapper.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

export const AuthProvider = ({ children }) => {
  const domain = AUTH0_API.domain ?? '';

  const clientId = AUTH0_API.clientId ?? '';

  const redirectUri = AUTH0_API.callbackUrl ?? '';

  const onRedirectCallback = useCallback((appState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: 'http://localhost:3000',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience={`${process.env.REACT_APP_AUTH0_CALLBACK_URL}`}
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
