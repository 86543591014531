import { Navigate, useRoutes } from 'react-router-dom';

// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { AuthGuard } from 'src/auth/guard';
//
import OverviewAppPage from 'src/pages/dashboard/app';
import Dashboard from 'src/layouts/dashboard';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
// import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: `${process.env.REACT_APP_HOST_HOME}`,
      element: (
        <AuthGuard>
          <Dashboard>
            {/* <HomePage /> */}
            <OverviewAppPage />
          </Dashboard>
        </AuthGuard>
      ),
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/dashboard/404" replace /> },
  ]);
}
