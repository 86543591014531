import PropTypes from 'prop-types';
import { Typography, Box, Stack } from '@mui/material';
import React, { memo, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RHFTextField } from '../hook-form';

const DynamicItem = ({
  currentMenu,
  pathname,
  createMenuRef,
  closeFunc,
  groupItem,
  edit,
  flow,
  showgroup,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [showOptions, setshowOptions] = useState(showgroup);
  const { control, setValue, watch } = useFormContext();

  const values = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const handleAdd = () => {
    append({
      name: '',
      price: 0,
      image: null,
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const handleChangePrice = useCallback(
    (value, index) => {
      setValue(`options[${index}].price`, Number(value));
    },
    [setValue]
  );

  const handleChangeName = useCallback(
    (event, index) => {
      setValue(`options[${index}].name`, event);
    },
    [setValue]
  );

  // file functions
  // const handleDrop = useCallback(
  //   (acceptedFiles, index) => {
  //     const file = acceptedFiles[0];
  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     setValue(`options[${index}].image`, newFile);
  //   },
  //   [setValue]
  // );

  // const handleRemoveFile = useCallback(
  //   (index) => {
  //     setValue(`options[${index}].image`, null);
  //   },
  //   [setValue]
  // );

  // const handleRemoveAllFiles = useCallback(() => {
  //   console.log('ddd');
  // }, []);

  const renderInfo = (
    <Stack
      id="General-Info"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
        <Box>
          <Typography>Options</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', my: 2 }}>
          {fields.map((row, index) => (
            <Box key={row.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <Typography sx={{ mx: 1 }}>{index + 1}.</Typography>
              {/* <RHFUpload
                thumbnail
                name={`options[${index}].image`}
                maxSize={3145728}
                // onDrop={handleDrop}
                onDrop={(acceptedFiles) => handleDrop(acceptedFiles, index)}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
                onUpload={() => console.info('ON UPLOAD')}
              /> */}

              <RHFTextField
                name={`options[${index}].name`}
                label="Option name"
                // value={row.name}
                onChange={(e) => handleChangeName(e.target.value, index)}
                sx={{ mx: 1 }}
              />
              <RHFTextField
                name={`options[${index}].price`}
                label="Price"
                type="number"
                value={values.options[index]?.price || ''}
                onChange={(e) => handleChangePrice(e.target.value, index)}
                sx={{ mx: 1 }}
              />

              {index > 0 && (
                <Button type="button" onClick={() => handleRemove(index)} color="error">
                  Delete
                </Button>
              )}
            </Box>
          ))}
          <Button type="button" onClick={handleAdd} color="success">
            Add Row
          </Button>
        </Box>
      </Box>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
        backgroundColor: '#F5F5F5',
      }}
    >
      <Stack sx={{ width: '100%' }}>{renderInfo}</Stack>
    </Box>
  );
};

DynamicItem.propTypes = {
  currentMenu: PropTypes.object,
  pathname: PropTypes.string,
  createMenuRef: PropTypes.object,
  closeFunc: PropTypes.func,
  groupItem: PropTypes.object,
  edit: PropTypes.bool,
  flow: PropTypes.bool,
  showgroup: PropTypes.bool,
};

export default memo(DynamicItem);
