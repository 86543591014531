/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import groupReducer from '../features/group/groupSlice';
import savedItemReducer from '../features/savedItem/savedSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, groupReducer, savedItemReducer);

const store = configureStore({
  reducer: {
    group: persistedReducer,
    // saveditem: persistedReducer,
  },
});

const persistor = persistStore(store);

export { store, persistor };
