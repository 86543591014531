import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Container,
  Stack,
  Divider,
  Drawer,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import { FORMDETAILS } from 'src/_mock/_formdetails';
import { AVAILABILITY } from 'src/_mock/_availability';
import { ColorPicker } from 'src/components/color-utils';
import { COLOR_OPTIONS } from 'src/_mock/_calendar';
import { palette as themePalette } from 'src/theme/palette';
import { useSnackbar } from 'src/components/snackbar';
import { createMenu, editMenu, useGetAllGroups, useGetAllPrepStations } from 'src/api/apis';
import { mutate } from 'swr';
import { endpoints } from 'src/utils/axios';
import {
  filterMyGroup,
  removeSaveMyGroup,
  removeSaveMyItem,
  replaceMyGroup,
  saveMyGroup,
} from 'src/features/group/groupSlice';
import Iconify from '../iconify';
import { RHFSelect, RHFTextField, RHFMultiCheckbox, RHFSwitch } from '../hook-form';
import FormProvider from '../hook-form/form-provider';
import AddGroup from '../add-group/add-group';
import Scrollbar from '../scrollbar';
import CustomBreadcrumbs from '../custom-breadcrumbs/custom-breadcrumbs';

const CreateMenu = ({
  currentMenu,
  pathname,
  drawerstatus,
  closeMenu,
  breadcrumb,
  Menuprops,
  edit,
  flow,
}) => {
  const MenuSchema = Yup.object().shape({
    menuName: Yup.string().required('menuName is required'),
    availability: Yup.bool().required('availability is required'),
    // nickName: Yup.string(),
    color: Yup.string(),
    // taxRates: Yup.array().min(1, 'Choose at least one option'),
    taxRates: Yup.array(),
    calculatedPrice: Yup.bool(),
    taxException: Yup.bool(),
    // kitchenNickName: Yup.string(),
    // kdsColor: Yup.string(),
    prepStations: Yup.array().min(1, 'Choose at least one option'),
    // course: Yup.string(),
    pos: Yup.bool(),
    kiosk: Yup.bool(),
    onlineOrder: Yup.bool(),
    menuGroups: Yup.array(),
  });

  const savedgroup = useSelector((state) => state.group.savedgroup);

  const palette = themePalette('light');
  const [showGroupOption, setshowGroupOption] = useState(true);
  const [menuGroups, setMenuGroups] = useState([]);
  const [menuFlow, setFlow] = useState(true);
  const [editOption, seteditOption] = useState(false);
  const selectedObjectRef = useRef(null);
  const [ItemList, setItemList] = useState([]);
  const [selectedModifiers, setselectedModifiers] = useState([]);
  const dispatchedModifierRef = useRef(new Set());
  const [loading, setLoading] = useState(false);

  // Api handling
  const { prep } = useGetAllPrepStations();
  const { groups } = useGetAllGroups();

  const updatedPrepStations = prep?.map((item) => ({
    value: item.prepStationId,
    label: item.description,
    prepStation: item.item,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (flow === false) {
      setFlow(false);
    }
    if (edit) {
      setMenuGroups(Menuprops?.menuGroups);
      // eslint-disable-next-line array-callback-return
      // menuGroups?.map((item, index) => {
      //   item.calculatedPrice = true;
      // });
    }
  }, [menuGroups, edit, Menuprops?.menuGroups, flow]);

  console.log('');

  useEffect(() => {
    // dispatch(removeModifier());
    if (groups != null || groups !== undefined) {
      setItemList(groups);
    }
  }, [groups]);

  const handleAutocompleteChange2 = (event, newValue, reason, detail) => {
    if (reason === 'removeOption') {
      if (dispatchedModifierRef.current.has(detail.option.menuGroupId)) {
        dispatchedModifierRef.current.delete(detail.option.menuGroupId);
      }
    } else if (reason === 'clear') {
      dispatchedModifierRef.current.clear();
    }
    setselectedModifiers(newValue);
    newValue.forEach((item) => {
      if (!dispatchedModifierRef.current.has(item.menuGroupId)) {
        dispatch(saveMyGroup(item));
        dispatchedModifierRef.current.add(item.menuGroupId);
      }
    });
  };
  // Delete Item Option
  const deleteFunc = (item) => {
    dispatchedModifierRef.current.delete(item.menuGroupId);
    dispatch(filterMyGroup(item.menuGroupId));
  };
  const defaultValues = useMemo(() => {
    if (edit && Menuprops) {
      return {
        menuName: Menuprops?.menuName || '',
        availability: Menuprops?.availability || false,
        nickName: Menuprops?.nickName || '',
        prepStations: Menuprops?.prepStations || [],
        pos: Menuprops?.pos || false,
        kiosk: Menuprops?.pos || false,
        onlineOrder: Menuprops?.onlineOrder || false,
        menuGroups: Menuprops?.menuGroups || [],
      };
      // eslint-disable-next-line no-else-return
    } else {
      return {
        menuName: currentMenu?.menuName || '',
        availability: currentMenu?.availability || false,
        // nickName: currentMenu?.nickName || '',
        color: currentMenu?.color || '',
        // taxRates: currentMenu?.taxRates || [],
        // calculatedPrice: currentMenu?.calculatedPrice || false,
        // taxException: currentMenu?.taxException || false,
        // kitchenNickName: currentMenu?.kitchenNickName || '',
        // kdsColor: currentMenu?.kdsColor || '',
        prepStations: currentMenu?.prepStations || [],
        // course: currentMenu?.course || '',
        pos: currentMenu?.pos || false,
        kiosk: currentMenu?.kisok || false,
        onlineOrder: currentMenu?.onlineOrder || false,
        menuGroups: currentMenu?.menuGroups || [],
      };
    }
  }, [edit, currentMenu, Menuprops]);
  const methods = useForm({
    resolver: yupResolver(MenuSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    // formState: { isSubmitting },
    reset,
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Set default values when component is mounted
    reset(defaultValues);
    if (edit && Menuprops?.menuGroups !== null && Menuprops?.menuGroups !== undefined) {
      dispatch(replaceMyGroup(Menuprops?.menuGroups));
    }
  }, [reset, defaultValues, edit, dispatch, Menuprops?.menuGroups]);

  // const deleteMenuCallback = useCallback(async () => {
  //   try {
  //     await deleteMenu(Menuprops.menuId);
  //     closeMenu(false);
  //   } catch (error) {
  //     console.error(`Failed to delete menu with id ${menuGroups[0].menuGroupId}:`, error);
  //   }
  // }, [Menuprops, closeMenu, menuGroups]);

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    let mydata;
    try {
      mydata = { ...data, menuGroups };
      if (flow && savedgroup.length > 0) {
        const extractedIds = savedgroup.map((item) => item.menuGroupId);
        const nonNullIds = extractedIds.filter((id) => id !== null && id !== undefined);
        mydata = { ...data, menuGroups: nonNullIds };
      }

      if (flow === false && edit) {
        const idArray = Menuprops.menuGroups.map((item) => item.menuGroupId);
        mydata = { ...data, menuGroups: idArray };
      }
      let res;
      if (edit) {
        const id = Menuprops?.menuId;
        res = await editMenu(id, mydata);
      } else {
        res = await createMenu(mydata);
      }
      dispatch(removeSaveMyItem());
      dispatch(removeSaveMyGroup());
      dispatchedModifierRef.current.clear();
      setselectedModifiers([]);
      reset();
      if (res.status === 200) {
        enqueueSnackbar('changes successfully added!', { variant: 'success' });
      } else if (res.status !== 200 || res.status === 404) {
        enqueueSnackbar('Unable to save the changes', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    handleCloseDrawer();
    setLoading(false);
  });

  // Drawer
  const [openGroupDrawer, setopenGroupDrawer] = useState(false);
  const breadcrumblist = [
    ...breadcrumb,
    {
      name: 'Create Category',
      href: './',
    },
  ];

  const handleopenGroupDrawer = useCallback(() => {
    setopenGroupDrawer(true);
  }, []);
  const openGroup = useCallback(() => {
    seteditOption(false);
    setshowGroupOption(true);
    selectedObjectRef.current = { menuItems: [] };
    setFlow(true);
    setshowGroupOption(false);
    handleopenGroupDrawer();
    dispatch(removeSaveMyItem());
  }, [dispatch, handleopenGroupDrawer]);

  const OpenEditGroup = useCallback(
    (item) => {
      setFlow(true);
      seteditOption(true);
      selectedObjectRef.current = item;
      handleopenGroupDrawer();
    },
    [handleopenGroupDrawer]
  );

  useEffect(() => {
    if (drawerstatus === true) {
      setopenGroupDrawer(true);
    } else {
      setopenGroupDrawer(false);
    }
    if (openGroupDrawer) {
      handleCloseGroupDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleCloseDrawer = () => {
    closeMenu(false);
  };

  const handleCloseGroupDrawer = () => {
    setshowGroupOption(false);
    setopenGroupDrawer(false);
  };
  const handleCloseGroupDrawer2 = useCallback((val) => {
    setopenGroupDrawer(val);
    setopenGroupDrawer(false);
    mutate(endpoints.menuGroup.menu);
  }, []);

  const createMenuRef = useRef(null);

  const scrollToSection = useCallback((section) => {
    if (createMenuRef.current) {
      const targetSection = createMenuRef.current.querySelector(`#${section.replace(/ /g, '-')}`);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });

        // Check if the last item is visible and adjust the scroll
        const lastItem = FORMDETAILS[FORMDETAILS.length - 1];
        const lastItemElement = createMenuRef.current.querySelector(
          `#${lastItem.replace(/ /g, '-')}`
        );
        const containerBottom = createMenuRef.current.getBoundingClientRect().bottom;
        const lastItemBottom = lastItemElement.getBoundingClientRect().bottom;

        if (lastItemBottom > containerBottom) {
          createMenuRef.current.scrollTo({
            top: createMenuRef.current.scrollTop + (lastItemBottom - containerBottom),
            behavior: 'smooth',
          });
        }
      }
    }
  }, []);

  /// container functions
  const renderInfo = (
    <Stack
      id="General-Info"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3, marginTop: '20px' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600px', color: `grey.500` }}>
          General Info{' '}
        </Typography>
      </Box>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Menu Name</Typography>
        <RHFTextField name="menuName" label="Menu Name" />
      </Stack>
      <Stack spacing={1.5} sx={{ my: 2, width: '300px' }}>
        <RHFSelect
          native
          name="availability"
          label="Availability"
          InputLabelProps={{ shrink: true }}
        >
          {AVAILABILITY?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Stack>

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Pos nickname (if different than item)</Typography>
        <RHFTextField name="nickName" label="Pos nickname (if different than item)" />
      </Stack> */}

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> pos Color Button</Typography>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack>
    </Stack>
  );
  // const PricingTaxes = (
  //   <Stack
  //     id="Pricing-and-Taxes"
  //     sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
  //   >
  //     <Typography sx={{ color: `grey.500` }}>Pricing and Taxes </Typography>
  //     <Stack spacing={1.5} sx={{ my: 2 }}>
  //       <Typography variant="subtitle2">Tax Rates</Typography>
  //       <RHFMultiCheckbox
  //         sx={{ display: 'flex', flexDirection: 'column' }}
  //         row
  //         spacing={1}
  //         name="taxRates"
  //         options={updatedTaxRates}
  //       />
  //     </Stack>
  //     <Divider />
  //     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
  //       <Box>
  //         <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
  //           Calculated price with tax included
  //         </Typography>
  //         <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
  //           Tax is included in this items price.
  //         </Typography>
  //       </Box>
  //       <RHFSwitch name="calculatedPrice" label={null} sx={{ m: 0 }} />
  //     </Box>
  //     <Divider />
  //     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
  //       <Box>
  //         <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
  //           Take out exception
  //         </Typography>
  //         <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
  //           This item is taxed except when it is ordered for takeout.
  //         </Typography>
  //       </Box>
  //       <RHFSwitch name="taxException" label={null} sx={{ m: 0 }} />
  //     </Box>
  //   </Stack>
  // );
  const MenuGroups = (
    <Stack
      id="Menu-groups"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: `grey.500`, fontWeight: '600px' }}>
        Menu groups
      </Typography>

      {savedgroup?.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 2,
            boxShadow: '0px 10px 5px rgba(0, 167, 111, 1)',
            border: 1,
            borderRadius: '8px',
            my: 2,
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
              {item.groupName}
            </Typography>
            <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
              {item.kitchenNickName}
            </Typography>
          </Box>
          {/* <Iconify
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setOpenEditGroupItem(item);
            }}
            icon="eva:more-vertical-fill"
            sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
          /> */}

          <Box>
            <Iconify
              onClick={(event) => {
                OpenEditGroup(item);
                // setselectedObject(item);
                // drawerItem(item);
              }}
              icon="eva:edit-outline"
              sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
            />
            <Iconify
              onClick={(event) => {
                deleteFunc(item);
              }}
              icon="eva:trash-2-outline"
              sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      ))}

      <Button
        variant="outlined"
        color="success"
        sx={{ width: '200px', mt: 2 }}
        onClick={() => openGroup()}
      >
        <Box sx={{ mr: 1 }}>+</Box> Add another group
      </Button>
      <Stack spacing={1.5} sx={{ my: 2, width: '80%' }}>
        <Container>
          <Autocomplete
            multiple
            id="tags-standard"
            options={ItemList || []}
            getOptionLabel={(option) => option?.groupName}
            filterSelectedOptions
            value={selectedModifiers}
            // defaultValue={[top100Films[13]]}
            onChange={(event, list, reason, detail) => {
              handleAutocompleteChange2(event, list, reason, detail);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Add from existing Items"
                // placeholder="Favorites"
              />
            )}
          />
        </Container>
      </Stack>
    </Stack>
  );
  const KitchenPrep = (
    <Stack
      id="Kitchen-prep"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: `grey.500`, fontWeight: '600px' }}>
        Kitchen prep
      </Typography>
      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Kitchen nickname (if different than item name)</Typography>
        <RHFTextField name="kitchenNickName" />
      </Stack> */}

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> KDS color</Typography>
        <Controller
          name="kdsColor"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack> */}

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Prep Stations</Typography>
        <Typography>
          Items ordered from this menu are routed to the kitchens you select below
        </Typography>
        <RHFMultiCheckbox
          sx={{ display: 'flex', flexDirection: 'column' }}
          row
          spacing={1}
          name="prepStations"
          options={updatedPrepStations}
        />
      </Stack>

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography sx={{ fontSize: '16px' }}>Course</Typography>
        <Typography sx={{ fontSize: '12px', color: '#919EABA1' }}>
          Determine the order in which the items are sent to the kithcen
        </Typography>
        <RHFSelect native name="course" InputLabelProps={{ shrink: true }}>
          {COURSES?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Stack> */}
    </Stack>
  );
  const ChannelVisibility = (
    <Stack
      id="Channel-Visibility"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: `grey.500`, fontWeight: '600px', my: 2 }}>
        Channel Visibility
      </Typography>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              POS
            </Typography>
          </Box>
          <RHFSwitch name="pos" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              KIOSK
            </Typography>
          </Box>

          <RHFSwitch name="kiosk" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            my: 2,
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: '12px', fontWeight: '600px' }}>
              Online Ordering
            </Typography>
          </Box>

          <RHFSwitch name="onlineOrder" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
        backgroundColor: '#F5F5F5',
        // overflowY: 'auto',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ backgroundColor: '#F5F5F5', height: '80vh' }}>
            <Stack sx={{ pt: 2, backgroundColor: '#F5F5F5' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'between',
                  alignItems: 'center',

                  px: 10,
                }}
              >
                <Iconify
                  icon="eva:close-fill"
                  sx={{
                    width: '30px',
                    height: '30px',
                    pl: '-2rem',
                    cursor: 'pointer',
                  }}
                  onClick={handleCloseDrawer}
                />
                <Typography sx={{ fontSize: '32px', lineHeight: '48px', fontWeight: '700px' }}>
                  Main Menu
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 14,
                }}
              >
                {breadcrumb && <CustomBreadcrumbs links={breadcrumb} sx={{ pb: 5 }} />}
                <Box>
                  {/* <Button
                    size="large"
                    color="error"
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    sx={{ mx: 2 }}
                    onClick={() => deleteMenuCallback()}
                  >
                    Delete
                  </Button> */}

                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    onClick={() => onSubmit()}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Stack>

            <Scrollbar
              sx={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            >
              <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Stack sx={{ position: 'fixed', top: '200px' }}>
                    {FORMDETAILS?.map((item, index) => (
                      <Box
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: '#8E8E8E',
                          borderLeft: 4,
                          borderColor: 'gray',
                          px: '8px',
                          py: '8px',
                          '&:hover': {
                            color: `${palette.primary.main}`,
                            borderColor: `${palette.primary.main}`,
                          },
                          cursor: 'pointer',
                        }}
                        key={index}
                        onClick={() => scrollToSection(item)}
                      >
                        {item}
                      </Box>
                    ))}
                  </Stack>
                </Grid>
                <Grid
                  item
                  ref={createMenuRef}
                  sm={8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Container>
                    <Stack sx={{ width: '90%' }}>
                      {renderInfo}
                      {/* {PricingTaxes} */}
                      {MenuGroups}
                      {KitchenPrep}
                      {ChannelVisibility}
                    </Stack>
                  </Container>
                </Grid>
              </Grid>
            </Scrollbar>
          </Box>
        </FormProvider>
      </Stack>
      <Drawer
        open={openGroupDrawer}
        anchor="right"
        onClose={handleCloseGroupDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddGroup
          breadcrumb={breadcrumblist}
          flow={menuFlow}
          closeFunc={handleCloseGroupDrawer2}
          showgroup={showGroupOption}
          groupItem={selectedObjectRef?.current}
          edit={editOption}
        />
      </Drawer>
    </Box>
  );
};

CreateMenu.propTypes = {
  currentMenu: PropTypes.object,
  pathname: PropTypes.string,
  drawerstatus: PropTypes.bool,
  closeMenu: PropTypes.func,
  breadcrumb: PropTypes.array,
  Menuprops: PropTypes.object,
  edit: PropTypes.bool,
  flow: PropTypes.bool,
};

export default memo(CreateMenu);
